import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import { Button } from 'primereact/button';
import ShareProperty from "../../CommonDataTable/ShareProperty";
import { faClone,faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getClientFormFields, { SoldPriceOptions, getAmenities } from '../../../../config/budgetOptions';
import ConfirmationModal from '../../CommonModals/ConfirmationModal';
import { PropertyAeraOptions } from "../../../../config/configData";
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
// config file

const MyProperties = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  
  let setValueOnSuccessRef = useRef(null);
  const [selectedRowData, setSelectedRowData] = useState([])
  const [ openFormModal, setopenFormModal] = useState(false);
  const [actionApiUrls, setActionApiUrls] = useState('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [amenitiesOptions, setAmenitiesOptions] = useState([]);
  const [showDataTable, setShowDataTable] = useState(false);  
  const [selectedPropertyType, setSelectedPropertyType] = useState('non-builder');

  const params = useParams();
  let user = localStorage.getItem('loginCredentials') ? JSON.parse(localStorage.getItem('loginCredentials')) : {};
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("My Properties");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
    const fetchData = async () => {
      let amenities = await getAmenities();
      setAmenitiesOptions(amenities);
    };
    fetchData();
    setShowDataTable(true)
  }, []);

  useEffect(() => {
    setShowDataTable(false);
    setTimeout(() => {
    setShowDataTable(true);
    }, 50);
  }, [selectedPropertyType])

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const addClient = () => {
    setActionApiUrls(apiCalls.clients);
    setopenFormModal(true);
  }
  
  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "browsePhotos",
        fieldName: "browsePhotos",
        type: "fileUpload",
        placeholder: "Browse Photos",
        value: "",
        label: "Browse Photos",
        width: "120px",
        addFormOrder: 24,
        editFormOrder: 24,
        stringType: null,
        header: "Browse Photos",
        derivedValue: "browsePhotos=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "browsePhotos",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 4,
        isMultipleRequired: true,
        imagePath: "My Properties",
        textAlign: "center",
        show: true,
        field: "browsePhotos",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "title",
        fieldName: "title",
        type: "derivedFields",
        placeholder: "Title",
        value: "",
        label: "Title",
        width: "180px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: "String",
        header: "Title",
        derivedValue: "title=city+locality",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: ["city", "locality"],
        id: "title",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "title",
        fieldType: "Link",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
      },
      {
        name: "propertyFor",
        fieldName: "propertyFor",
        type: "radio",
        placeholder: "Property For",
        value: "",
        label: "Property For",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        header: "Property For",
        derivedValue: "propertyFor=propertyFor=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyFor",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "propertyFor",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyType",
        fieldName: "propertyType",
        type: "radio",
        placeholder: "Property Type",
        value: "",
        label: "Type",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: null,
        header: "Type",
        derivedValue: "propertyType=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1RK", value: "1Rk", id: "msr", color: "primary"},
          { label: "Flat", value: "Flat", id: "msr" ,  color: "secondary"},
          { label: "Duplex", value: "Duplex", id: "msr", color: "warning" },
          { label: "Bungalow", value: "Bungalow", id: "msr", color: "success" },
          { label: "Commercial", value: "Commercial", id: "msr", color: "danger" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1RK", value: "1Rk", id: "msr", color: "primary" },
          { label: "Flat", value: "Flat", id: "msr", color: "secondary" },
          { label: "Duplex", value: "Duplex", id: "msr", color: "warning" },
          { label: "Bungalow", value: "Bungalow", id: "msr" , color: "success"},
          { label: "Commercial", value: "Commercial", id: "msr", color: "danger" },
        ],
        textAlign: "center",
        show: true,
        field: "propertyType",
        showOrHideFields: [],
        label0: "1RK",
        value0: "1Rk",
        label1: "Flat",
        value1: "Flat",
        label2: "Duplex",
        value2: "Duplex",
        label3: "Bungalow",
        value3: "Banglow",
        label4: "Commercial",
        value4: "Commercial",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "price",
        fieldName: "price",
        type: "number",
        placeholder: "price",
        value: "",
        label: "Price",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        header: "Price / Rent",
        derivedValue: "price=price=price=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "price",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "price",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType : 'customType',
        customFieldType : 'amount',
        filterPlaceholder : `Enter amount(e.g., 100000)`
      },
      {
        name: "rent",
        fieldName: "rent",
        type: "number",
        placeholder: "Rent",
        value: "",
        label: "Rent",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        stringType: null,
        header: "Rent",
        derivedValue: "rent=rent=rent=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "rent",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: false,
        field: "rent",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
        fieldType : 'customType',
        customFieldType : 'amount'
      },
      {
        name: "deposite",
        fieldName: "deposite",
        type: "number",
        placeholder: "Deposit",
        value: "",
        label: "Deposit",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        stringType: null,
        header: "Deposit",
        derivedValue: "deposite=deposite=deposite=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "deposite",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "deposite",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType : 'customType',
        customFieldType : 'amount',
        filterPlaceholder : `Enter amount(e.g., 1000)`
      },
      {
        name: "facing",
        fieldName: "facing",
        type: "dropDown",
        placeholder: "Facing",
        value: "",
        label: "Facing",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        stringType: null,
        header: "Facing",
        derivedValue: "facing=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "facing",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "facing",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "city",
        fieldName: "city",
        type: "text",
        placeholder: "City",
        value: "",
        label: "City",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 5,
        stringType: null,
        header: "City",
        derivedValue: "city=city=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "locality",
        fieldName: "locality",
        type: "text",
        placeholder: "Locality",
        value: "",
        label: "Locality",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 4,
        stringType: null,
        header: "Locality",
        derivedValue: "locality=locality=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "locality",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "locality",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "availability",
        fieldName: "availability",
        type: "radio",
        placeholder: "Availability",
        value: "",
        label: "Availability",
        width: "200px",
        addFormOrder: 19,
        editFormOrder: 19,
        stringType: null,
        header: "Availability",
        derivedValue: "availability=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "availability",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
            color : 'primary'
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" , color : 'success'},
        ],
        fieldType: "radio",
        filterElement: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
            color : 'primary',
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" , color : 'success'},
        ],
        textAlign: "center",
        show: true,
        field: "availability",
        showOrHideFields: [],
        label0: "Under Construction",
        value0: "Under Construction",
        label1: "Ready To Move",
        value1: "Ready To Move",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "furnishingType",
        fieldName: "furnishingType",
        type: "radio",
        placeholder: "Furnishing Type",
        value: "",
        label: "Furnishing Type",
        width: "180px",
        addFormOrder: 20,
        editFormOrder: 20,
        stringType: null,
        header: "Furnishing Type",
        derivedValue: "furnishingType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "furnishingType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr", color : 'primary' },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" , color : 'secondary' },
          { label: "Furnished", value: "Furnished", id: "msr" , color : 'warning' },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr", color : 'primary'  },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr", color : 'secondary'  },
          { label: "Furnished", value: "Furnished", id: "msr", color : 'warning'  },
        ],
        textAlign: "center",
        show: true,
        field: "furnishingType",
        showOrHideFields: [],
        label0: "Un Furnished",
        value0: "Un Furnished",
        label1: "Semi Furnished",
        value1: "Semi Furnished",
        label2: "Furnished",
        value2: "Furnished",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "landmark",
        fieldName: "landmark",
        type: "text",
        placeholder: "Landmark",
        value: "",
        label: "Landmark",
        width: "180px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        header: "Landmark",
        derivedValue: "landmark=landmark=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "landmark",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "landmark",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "plotArea",
        fieldName: "plotArea",
        type: "number",
        placeholder: "Plot Area",
        value: "",
        label: "Plot Area",
        width: "140px",
        addFormOrder: 11,
        editFormOrder: 11,
        stringType: null,
        header: "Plot Area",
        derivedValue: "plotArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "plotArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "plotArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType : 'customType',
        customFieldType : 'attachField',
        attachField : 'areaUnits',
        filterPlaceholder : `Enter area(e.g., 1000)`

      },
      {
        name: "carpetArea",
        fieldName: "carpetArea",
        type: "number",
        placeholder: "Carpet Area",
        value: "",
        label: "Carpet Area",
        width: "160px",
        addFormOrder: 13,
        editFormOrder: 12,
        stringType: null,
        header: "Carpet Area",
        derivedValue: "carpetArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "carpetArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "carpetArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType : 'customType',
        customFieldType : 'attachField',
        attachField : 'areaUnits',
        filterPlaceholder : `Enter area(e.g., 1000)`
      },
      {
        name: "builtUpArea",
        fieldName: "builtUpArea",
        type: "number",
        placeholder: "Built Up Area",
        value: "",
        label: "Built Up Area",
        width: "180px",
        addFormOrder: 12,
        editFormOrder: 13,
        stringType: null,
        header: "Built Up Area",
        derivedValue: "builtUpArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "builtUpArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "builtUpArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType : 'customType',
        customFieldType : 'attachField',
        attachField : 'areaUnits',
        filterPlaceholder : `Enter area(e.g., 1000)`
      },
      {
        name: "numberOfFloors",
        fieldName: "numberOfFloors",
        type: "number",
        placeholder: "Number Of Floors",
        value: "",
        label: "Number Of Floors",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        stringType: null,
        header: "Number Of Floors",
        derivedValue: "numberOfFloors=numberOfFloors=numberOfFloors=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "numberOfFloors",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "numberOfFloors",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "floorNumber",
        fieldName: "floorNumber",
        type: "dropDown",
        placeholder: "Floor Number",
        value: "",
        label: "Floor Number",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 25,
        stringType: null,
        header: "Floor Number",
        derivedValue: "floorNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "floorNumber",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        fieldType: "dropDown",
        filterElement: [
          {
            label: "High",
            value: "High",
            color: "primary",
            id: "hig",
          },
          {
            label: "Medium",
            value: "Medium",
            color: "secondary",
            id: "me",
          },
          {
            label: "Low",
            value: "Low",
            color: "success",
            id: "lo",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "floorNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "amenties",
        fieldName: "amenties",
        type: "checkbox",
        placeholder: "Amenties",
        value: "",
        label: "Amenities",
        width: "120px",
        addFormOrder: 21,
        editFormOrder: 21,
        stringType: null,
        header: "Amenities",
        derivedValue: "amenties=amenties=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "amenties",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "amenties",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "checkbox",
        filterElement: amenitiesOptions,
        multiple: true,
        multipleSelect: true,
      },
      {
        name: "propertyDescription",
        fieldName: "propertyDescription",
        type: "textarea",
        placeholder: "Property Description",
        value: "",
        label: "Property Description",
        width: "120px",
        addFormOrder: 23,
        editFormOrder: 23,
        stringType: null,
        header: "Description",
        derivedValue: "propertyDescription=propertyDescription=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "propertyDescription",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "propertyDescription",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "client",
        fieldName: "client",
        type: "relateAutoComplete",
        placeholder: "Client",
        value: "",
        label: "Client",
        width: "120px",
        addFormOrder: 22,
        editFormOrder: 22,
        stringType: null,
        header: "Client",
        derivedValue: "client=client=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "client",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1008,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["firstName"],
        controllerName: "Clients",
        searchApi: "clients",
        textAlign: "left",
        show: true,
        field: "client",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "monthlyMaintenance",
        fieldName: "monthlyMaintenance",
        type: "number",
        placeholder: "MonthlyMaintenance",
        value: "",
        label: "MonthlyMaintenance",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Monthly Maintenance",
        derivedValue: "monthlyMaintenance=monthlyMaintenance=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "monthlyMaintenance",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "right",
        show: true,
        field: "monthlyMaintenanceInIndianFormat",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType : 'customType',
        customFieldType : 'amount',
        filterPlaceholder : `Enter amount(e.g., 1000)`
      },
      {
        name: "bedRooms",
        fieldName: "bedRooms",
        type: "radio",
        placeholder: "Bed Rooms",
        value: "",
        label: "Bed Rooms",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        stringType: null,
        header: "BedRooms",
        derivedValue: "bedRooms=bedRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "bedRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "bedRooms",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "bathRooms",
        fieldName: "bathRooms",
        type: "radio",
        placeholder: "Bath Rooms",
        value: "",
        label: "Bath Rooms",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        stringType: null,
        header: "BathRooms",
        derivedValue: "bathRooms=bathRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "bathRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "bathRooms",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyStatus",
        fieldName: "propertyStatus",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 25,
        stringType: null,
        header: "Status",
        derivedValue: "propertyStatus=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "propertyStatus",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "Expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Need Approval",
            value: "Need Approval",
            color: "primary",
            dependentField: "",
            id: "apr",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Need Approval",
            value: "Need Approval",
            color: "primary",
            dependentField: "",
            id: "apr",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "Expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "propertyStatus",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      
      {
        name: "agentViewCount",
        fieldName: "agentViewCount",
        type: "text",
        placeholder: "Views",
        value: "",
        label: "Views",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        header: "Views",
        derivedValue: "agentViewCount=agentViewCount=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "agentViewCount",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "right",
        show: false,
        field: "onViewCount",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
        fieldType : 'customType',
        customFieldType : 'icon',
        iconName : 'eye',
        showDefalutValue : '0'
      },
      {
        name: "agentSummaryViewCount",
        fieldName: "agentSummaryViewCount",
        type: "text",
        placeholder: "Summary View",
        value: "",
        label: "Views",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        header: "Views",
        derivedValue: "agentSummaryViewCount=agentSummaryViewCount=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "agentSummaryViewCount",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "right",
        show: false,
        field: "agentSummaryViewCount",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType : 'customType',
        customFieldType : 'icon',
        iconName : 'eye',
        showDefalutValue : '0'
      },
      {
        name: "expiringSoon",
        fieldName: "expiringSoon",
        type: "text",
        placeholder: "Expiring Soon",
        value: "",
        label: "Expiring Soon",
        width: "250px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        header: "Expiring Soon",
        derivedValue: "expiringSoon=expiringSoon=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "expiringSoon",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "expiringSoon",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType : 'customType',
        customFieldType : 'parentType',
        parentType : actionFieldWithValue
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created By",
        derivedValue: "createdByName=createdByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 25,
        editFormOrder: 26,
        header: "Updated By",
        derivedValue: "updatedByName=updatedByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 110,
        header: "Created",
        derivedValue: "created=undefined",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
        customActions : customActions
      },
      {
        name: "builderName",
        fieldName: "builderName",
        type: "text",
        placeholder: "Builder Name",
        value: "",
        label: "Builder Name",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Builder Name",
        derivedValue: "builderName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "builderName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "builderName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "availableDate",
        fieldName: "availableDate",
        type: "date",
        placeholder: "Available Date",
        value: "",
        label: "Available Date",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Available Date",
        derivedValue: "availableDate=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        id: "availableDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "availableDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "possessionDate",
        fieldName: "possessionDate",
        type: "date",
        placeholder: "Possession Date",
        value: "",
        label: "Possession Date",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Possession Date",
        derivedValue: "possessionDate=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        id: "possessionDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "possessionDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "priceForSqft",
        fieldName: "priceForSqft",
        type: "number",
        placeholder: "Price/Sqft",
        value: "",
        label: "Price/Sqft",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Price/Sqft",
        derivedValue: "priceForSqft=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "priceForSqft",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "priceForSqft",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'amount',
        filterPlaceholder: `Enter amount(e.g., 100000)`
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "title",
        fieldName: "title",
        type: "derivedFields",
        placeholder: "Title",
        value: "",
        label: "Title",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: "String",
        derivedValue: "title=city+locality",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: ["city", "locality"],
        id: "title",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      // {
      //   name: "validateRequirement",
      //   fieldName: "validateRequirement",
      //   type: "textarea",
      //   placeholder: "ValidateRequirement",
      //   value: "",
      //   label: "Validate Requirement",
      //   width: "120px",
      //   addFormOrder: 1,
      //   editFormOrder: 1,
      //   stringType: null,
      //   derivedValue: "validateRequirement=validateRequirement=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   filter: false,
      //   id: "validateRequirement",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinregisterForm: "",
      //   displayinlist: "false",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: "",
      //   globalSearchField: "true",
      //   controllerId: null,
      //   isClickable: "",
      //   isAddToIndex: false,
      //   isBulkUploadField: true,
      //   show: true,
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   isAddFormHidden: false,
      //   isEditFormHidden: false,
      // },
      {
        name: "propertyFor",
        fieldName: "propertyFor",
        type: "radio",
        placeholder: "Property For",
        value: "Sale",
        label: "Property For",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        derivedValue: "propertyFor=propertyFor=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyFor",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        dependent: [{ "Sale": ["price"] }, { "Rent": ["rent", 'deposite', 'monthlyMaintenance', 'availableDate']}],
      },
      {
        name: "propertyType",
        fieldName: "propertyType",
        type: "radio",
        placeholder: "Property Type",
        value: "Flat",
        label: "Property Type",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: null,
        derivedValue: "propertyType=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1RK", value: "1Rk", id: "msr" },
          { label: "Flat", value: "Flat", id: "msr" },
          { label: "Duplex", value: "Duplex", id: "msr" },
          { label: "Bungalow", value: "Bungalow", id: "msr" },
          { label: "Commercial", value: "Commercial", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1RK", value: "1Rk", id: "msr" },
          { label: "Flat", value: "Flat", id: "msr" },
          { label: "Duplex", value: "Duplex", id: "msr" },
          { label: "Bungalow", value: "Bungalow", id: "msr" },
          { label: "Commercial", value: "Commercial", id: "msr" },
        ],
        show: true,
        showOrHideFields: [],
        label0: "1RK",
        value0: "1Rk",
        label1: "Flat",
        value1: "Flat",
        label2: "Duplex",
        value2: "Duplex",
        label3: "Bungalow",
        value3: "Banglow",
        label4: "Commercial",
        value4: "Commercial",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        dependent: [{ "1Rk": ['carpetArea', 'builtUpArea'] }, { "Flat": ["bedRooms", "bathRooms", 'carpetArea', 'builtUpArea'] }, { "Duplex": ["bedRooms", "bathRooms", 'carpetArea', 'builtUpArea'] }, {"Bungalow": ["bedRooms", "bathRooms", 'carpetArea','builtUpArea']},{"Commercial": ["plotArea"]}],
      },
      {
        name: "price",
        fieldName: "price",
        type: "number",
        placeholder: "Price",
        value: "",
        label: "Price",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        stringType: null,
        derivedValue: "price=price=price=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "price",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customWidth:"col-2",
        displayPrice: true
      },
      {
        name: "builderName",
        fieldName: "builderName",
        type: "text",
        placeholder: "Builder Name",
        value: "",
        label: "Builder Name",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        derivedValue: "builderName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "builderName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        combinedDependent: { 'hiddenFields': 'price', options: [{ key: 'propertyFor', value: 'Sale' }, { key: 'availability', value: 'Under Construction' }] }
      },
      {
        name: "possessionDate",
        fieldName: "possessionDate",
        type: "date",
        placeholder: "Possession Date",
        value: "",
        label: "Possession Date",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        stringType: null,
        derivedValue: "possessionDate=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        id: "possessionDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        minDate: new Date(),
        combinedDependent: { 'hiddenFields': 'price', options: [{ key: 'propertyFor', value: 'Sale' }, { key: 'availability', value: 'Under Construction' }] }
      },
      {
        name: "priceForSqft",
        fieldName: "priceForSqft",
        type: "number",
        placeholder: "Price",
        value: "",
        label: "Price per unit",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        stringType: null,
        derivedValue: "priceForSqft=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "priceForSqft",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customWidth:"col-2",
        displayPrice: true,
        valuePattern: /^\d{1,5}$/,
        combinedDependent: { 'hiddenFields': 'price', options: [{ key: 'propertyFor', value: 'Sale' }, { key: 'availability', value: 'Under Construction' }] }
      },
      {
        name: "availableDate",
        fieldName: "availableDate",
        type: "date",
        placeholder: "Available Date",
        value: "",
        label: "Available Date",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        stringType: null,
        derivedValue: "availableDate=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        id: "availableDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "rent",
        fieldName: "rent",
        type: "number",
        placeholder: "Rent",
        value: "",
        label: "Rent",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        stringType: null,
        derivedValue: "rent=rent=rent=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "rent",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        displayPrice: true
      },
      {
        name: "deposite",
        fieldName: "deposite",
        type: "number",
        placeholder: "Deposit",
        value: "",
        label: "Deposit",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        stringType: null,
        derivedValue: "deposite=deposite=deposite=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "Deposit",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        displayPrice: true
      },
      {
        name: "facing",
        fieldName: "facing",
        type: "dropDown",
        placeholder: "Facing",
        value: "",
        label: "Facing",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        stringType: null,
        derivedValue: "facing=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "facing",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "areaUnits",
        fieldName: "areaUnits",
        type: "dropDown",
        placeholder: "Area Units",
        value: "sqft",
        label: "Area Units",
        width: "120px",
        addFormOrder: 6,
        editFormOrder:6,
        stringType: null,
        derivedValue: "areaUnits=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "areaUnits",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: PropertyAeraOptions,
        fieldType: "dropDown",
        filterElement: PropertyAeraOptions,
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        customWidth:"col-2",
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "city",
        fieldName: "city",
        type: "relateAutoComplete",
        placeholder: "City",
        value: "",
        label: "City",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        stringType: null,
        derivedValue: "city=city=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        searchField: "city",
        fieldType: "relateAutoComplete",
        controllerName: "localities",
        searchApi: "localities",
      },
      {
        name: "locality",
        fieldName: "locality",
        type: "relateAutoComplete",
        placeholder: "Locality",
        value: "",
        label: "Locality",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "locality=locality=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "locality",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        searchField: "area",
        fieldType: "relateAutoComplete",
        controllerName: "localities",
        searchApi: "localities",
        hasDependencyField: "city",
      },
      {
        name: "landmark",
        fieldName: "landmark",
        type: "text",
        placeholder: "Landmark",
        value: "",
        label: "Landmark",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        stringType: null,
        derivedValue: "landmark=landmark=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "landmark",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        maxLength: 250
      },
      {
        name: "plotArea",
        fieldName: "plotArea",
        type: "number",
        placeholder: "Plot Area",
        value: "",
        label: "Plot Area",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        stringType: null,
        derivedValue: "plotArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "plotArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldType : 'customType',
        customFieldType : 'attachField',
        attachField: 'areaUnits',
        valuePattern: /^\d{1,5}$/
      },
      {
        name: "carpetArea",
        fieldName: "carpetArea",
        type: "number",
        placeholder: "Carpet Area",
        value: "",
        label: "Carpet Area",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        stringType: null,
        derivedValue: "carpetArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "carpetArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        valuePattern: /^\d{1,5}$/
      },
      {
        name: "builtUpArea",
        fieldName: "builtUpArea",
        type: "number",
        placeholder: "Built Up Area",
        value: "",
        label: "Built Up Area",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        stringType: null,
        derivedValue: "builtUpArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "builtUpArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        valuePattern: /^\d{1,5}$/
      },
      {
        name: "floorNumber",
        fieldName: "floorNumber",
        type: "dropDown",
        placeholder: "Floor Number",
        value: "",
        label: "Floor Number",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        stringType: null,
        header: "Floor Number",
        derivedValue: "floorNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "floorNumber",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        fieldType: "dropDown",
        options: [
          {
            label: "High",
            value: "High",
            color: "primary",
            id: "hig",
          },
          {
            label: "Medium",
            value: "Medium",
            color: "secondary",
            id: "me",
          },
          {
            label: "Low",
            value: "Low",
            color: "success",
            id: "lo",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "floorNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfFloors",
        fieldName: "numberOfFloors",
        type: "number",
        placeholder: "Number Of Floors",
        value: "",
        label: "Number Of Floors",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        stringType: null,
        derivedValue: "numberOfFloors=numberOfFloors=numberOfFloors=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "numberOfFloors",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        valuePattern: /^\d{1,5}$/
      },
      {
        name: "availability",
        fieldName: "availability",
        type: "radio",
        placeholder: "Availability",
        value: "Ready To Move",
        label: "Availability",
        width: "200px",
        addFormOrder: 4,
        editFormOrder: 4,
        stringType: null,
        derivedValue: "availability=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "availability",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
        ],
        show: true,
        showOrHideFields: [],
        label0: "Under Construction",
        value0: "Under Construction",
        label1: "Ready To Move",
        value1: "Ready To Move",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        dependent: [{ "Under Construction": ['priceForSqft', 'possessionDate', 'builderName'] }],
      },
      {
        name: "furnishingType",
        fieldName: "furnishingType",
        type: "radio",
        placeholder: "Furnishing Type",
        value: "Un Furnished",
        label: "Furnishing Type",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        stringType: null,
        derivedValue: "furnishingType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "furnishingType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr" },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" },
          { label: "Furnished", value: "Furnished", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr" },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" },
          { label: "Furnished", value: "Furnished", id: "msr" },
        ],
        show: true,
        showOrHideFields: [],
        label0: "Un Furnished",
        value0: "Un Furnished",
        label1: "Semi Furnished",
        value1: "Semi Furnished",
        label2: "Furnished",
        value2: "Furnished",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "amenties",
        fieldName: "amenties",
        type: "checkbox",
        placeholder: "Amenties",
        value: "",
        label: "Amenities",
        width: "120px",
        addFormOrder: 24,
        editFormOrder: 24,
        stringType: null,
        derivedValue: "amenties=amenties=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "amenties",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        options: amenitiesOptions,
        fieldType: "checkbox",
        multiple: true,
        multipleSelect: true,
        optionsWidth: 'col-sm-4'
      },
      {
        name: "propertyDescription",
        fieldName: "propertyDescription",
        type: "textarea",
        placeholder: "Property Description",
        value: "",
        label: "Property Description",
        width: "120px",
        addFormOrder: 23,
        editFormOrder: 23,
        stringType: null,
        derivedValue: "propertyDescription=propertyDescription=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "propertyDescription",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "browsePhotos",
        fieldName: "browsePhotos",
        type: "fileUpload",
        placeholder: "Browse Photos",
        value: "",
        label: "Browse Photos",
        width: "120px",
        addFormOrder: 25,
        editFormOrder: 25,
        stringType: null,
        derivedValue: "browsePhotos=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "browsePhotos",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 10,
        acceptType: "image/*, video/*, .pdf",
        isMultipleRequired: true,
        imagePath: "My Properties",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "client",
        fieldName: "client",
        type: "relateAutoComplete",
        placeholder: "Client",
        value: "",
        label: "Client",
        width: "120px",
        addFormOrder: 21,
        editFormOrder: 21,
        stringType: null,
        derivedValue: "client=client=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "client",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1008,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["firstName"],
        controllerName: "Clients",
        searchApi: "clients",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customWidth : 'col-md-3',
        criteria: [{ "key": "type", "value": ["Seller"], "type": "in" }, { key: "status", value: ["Active"], type: "in" }]
      },
      {
        name: "addCLient",
        fieldName: "addClient",
        type: "customFormField",
        placeholder: "Add Client",
        label: "Add Client",
        width: "120px",
        addFormOrder: 22,
        editFormOrder: 22,
        id: "addCLient",
        displayinaddForm: "true",
        displayineditForm: "true",
        isAddFormHidden: true,
        isEditFormHidden: true,
        customFormField : onCLickAddClient,
        show : true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customWidth : 'col-md-1'
      },
      {
        name: "monthlyMaintenance",
        fieldName: "monthlyMaintenance",
        type: "number",
        placeholder: "Monthly Maintenance",
        value: "",
        label: "Monthly Maintenance",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        stringType: null,
        derivedValue: "monthlyMaintenance=monthlyMaintenance=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "monthlyMaintenance",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        displayPrice: true
      },
      {
        name: "bedRooms",
        fieldName: "bedRooms",
        type: "radio",
        placeholder: "Bed Rooms",
        value: "",
        label: "BedRooms",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        stringType: null,
        derivedValue: "bedRooms=bedRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "bedRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "bathRooms",
        fieldName: "bathRooms",
        type: "radio",
        placeholder: "Bath Rooms",
        value: "",
        label: "BathRooms",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        stringType: null,
        derivedValue: "bathRooms=bathRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "bathRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "propertyStatus",
        fieldName: "propertyStatus",
        type: "dropDown",
        placeholder: "Property Status",
        value: "",
        label: "Property Status",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 25,
        stringType: null,
        derivedValue: "propertyStatus=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "propertyStatus",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "Expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "Expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "createdByName=createdByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 25,
        editFormOrder: 26,
        derivedValue: "updatedByName=updatedByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        isBulkUploadField: false,
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
    ];
  };

  const getBuilderTableFields = () => {

    return [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "S.No",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Agent",
        derivedValue:
          "createdByName=createdByName=createdByName=createdByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "projectName",
        fieldName: "projectName",
        type: "text",
        placeholder: "Project Name",
        value: "",
        label: "Project Name",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Project Name",
        derivedValue: "projectName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "projectName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "projectName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
      },
      {
        name: "client",
        fieldName: "client",
        type: "relateAutoComplete",
        placeholder: "Builder Name",
        value: "",
        label: "Builder Name",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Builder Name",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "client",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        controllerName: "Clients",
        searchApi: "clients",
        textAlign: "left",
        show: true,
        field: "client",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyType",
        fieldName: "propertyType",
        type: "radio",
        placeholder: "Type",
        value: "",
        label: "Type",
        width: "150px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: null,
        header: "Type",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Apartment", value: "apartment", id: "msr" },
          { label: "Villas", value: "villas", id: "msr" },
          { label: "Commercial", value: "commercial", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Apartment", value: "apartment", id: "msr" },
          { label: "Villas", value: "villas", id: "msr" },
          { label: "Commercial", value: "commercial", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "propertyType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        fieldName: "address",
        type: "text",
        placeholder: "Street Address",
        value: "",
        label: "Street Address",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Street Address",
        derivedValue: "streetAddress=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "address",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "minPrice",
        fieldName: "minPrice",
        type: "number",
        placeholder: "Min Price",
        value: "",
        label: "Min Price",
        width: "180px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        header: "Min Price",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "minPrice",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "minPrice",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'amount',
        filterPlaceholder: `Enter amount(e.g., 100000)`
      }, 
      {
        name: "maxPrice",
        fieldName: "maxPrice",
        type: "number",
        placeholder: "Max Price",
        value: "",
        label: "Max Price",
        width: "180px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        header: "Max Price",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "maxPrice",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "maxPrice",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'amount',
        filterPlaceholder: `Enter amount(e.g., 100000)`
      },
      {
        name: "priceForSqft",
        fieldName: "priceForSqft",
        type: "number",
        placeholder: "Price/Sqft",
        value: "",
        label: "Price/Sqft",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Price/Sqft",
        derivedValue: "priceForSqft=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "priceForSqft",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "priceForSqft",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'amount',
        filterPlaceholder: `Enter amount(e.g., 100000)`
      },
      {
        name: "facing",
        fieldName: "facing",
        type: "checkbox",
        placeholder: "Facing",
        value: "",
        label: "Facing",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        stringType: null,
        header: "Facing",
        derivedValue: "facing=facing=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "facing",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        fieldType: "checkbox",
        filterElement: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "facing",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        multiple: true,
      },
      {
        name: "city",
        fieldName: "city",
        type: "text",
        placeholder: "City",
        value: "",
        label: "City",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 5,
        stringType: null,
        header: "City",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: false,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "locality",
        fieldName: "locality",
        type: "text",
        placeholder: "Locality",
        value: "",
        label: "Locality",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 4,
        stringType: null,
        header: "Locality",
        derivedValue: "locality=locality=locality=locality=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "locality",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: false,
        field: "locality",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "minArea",
        fieldName: "minArea",
        type: "number",
        placeholder: "Min Area",
        value: "",
        label: "Min Area",
        width: "160px",
        addFormOrder: 12,
        editFormOrder: 13,
        stringType: null,
        header: "Min Area",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "minArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "minArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "maxArea",
        fieldName: "maxArea",
        type: "number",
        placeholder: "Max Area",
        value: "",
        label: "Max Area",
        width: "160px",
        addFormOrder: 12,
        editFormOrder: 13,
        stringType: null,
        header: "Max Area",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "maxArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "maxArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfFloors",
        fieldName: "numberOfFloors",
        type: "number",
        placeholder: "Total Floors",
        value: "",
        label: "Total Floors",
        width: "180px",
        addFormOrder: 12,
        editFormOrder: 12,
        stringType: null,
        header: "Total Floors",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "totalFloors",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: false,
        field: "numberOfFloors",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "availability",
        fieldName: "availability",
        type: "radio",
        placeholder: "Availability",
        value: "",
        label: "Availability",
        width: "150px",
        addFormOrder: 19,
        editFormOrder: 19,
        stringType: null,
        header: "Availability",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "availability",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        fieldType: "radio",
        filterElement: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
          { label: "Up Coming", value: "upComing", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "availability",
        showOrHideFields: [],
        label0: "Under Construction",
        value0: "Under Construction",
        label1: "Ready To Move",
        value1: "Ready To Move",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "amenties",
        fieldName: "amenties",
        type: "checkbox",
        placeholder: "Amenties",
        value: "",
        label: "Amenities",
        width: "120px",
        addFormOrder: 21,
        editFormOrder: 21,
        stringType: null,
        header: "Amenities",
        derivedValue: "amenties=amenties=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "amenties",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "amenties",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "checkbox",
        filterElement: amenitiesOptions,
        multiple: true,
        multipleSelect: true,
      },
      {
        name: "description",
        fieldName: "description",
        type: "textarea",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "200px",
        addFormOrder: 23,
        editFormOrder: 23,
        stringType: null,
        header: "Description",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: false,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "minBedRooms",
        fieldName: "minBedRooms",
        type: "radio",
        placeholder: "Min BedRooms",
        value: "",
        label: "Min BedRooms",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        stringType: null,
        header: "Min BedRooms",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "minBedRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "1.5", value: "1.5", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "2.5", value: "2.5", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "3.5", value: "3.5", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "4.5", value: "4.5", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "5.5", value: "5.5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
          { label: "6.5", value: "6.5", id: "msr" },
        ],
        textAlign: "center",
        show: false,
        field: "minBedRooms",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "maxBedRooms",
        fieldName: "maxBedRooms",
        type: "radio",
        placeholder: "Max BedRooms",
        value: "",
        label: "Max BedRooms",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        stringType: null,
        header: "Max BedRooms",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "maxBedRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "1.5", value: "1.5", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "2.5", value: "2.5", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "3.5", value: "3.5", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "4.5", value: "4.5", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "5.5", value: "5.5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
          { label: "6.5", value: "6.5", id: "msr" },
        ],
        textAlign: "center",
        show: false,
        field: "maxBedRooms",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "minBathRooms",
        fieldName: "minBathRooms",
        type: "radio",
        placeholder: "Min BathRooms",
        value: "",
        label: "Min BathRooms",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        stringType: null,
        header: "Min BathRooms",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "minBathRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
        ],
        textAlign: "center",
        show: false,
        field: "minBathRooms",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "maxBathRooms",
        fieldName: "maxBathRooms",
        type: "radio",
        placeholder: "Max BathRooms",
        value: "",
        label: "Max BathRooms",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        stringType: null,
        header: "Max BathRooms",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "maxBathRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
        ],
        textAlign: "center",
        show: false,
        field: "maxBathRooms",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyStatus",
        fieldName: "propertyStatus",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 25,
        stringType: null,
        header: "Status",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "propertyStatus",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "Expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Need Approval",
            value: "Need Approval",
            color: "primary",
            dependentField: "",
            id: "apr",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Need Approval",
            value: "Need Approval",
            color: "primary",
            dependentField: "",
            id: "apr",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "propertyStatus",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 25,
        editFormOrder: 26,
        header: "Updated By",
        derivedValue:
          "updatedByName=updatedByName=updatedByName=updatedByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: false,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=created=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: false,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "possessionDate",
        fieldName: "possessionDate",
        type: "date",
        placeholder: "possession Date",
        value: "",
        label: "possession Date",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "possession Date",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        id: "possessionDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "possessionDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "zipCode",
        fieldName: "zipCode",
        type: "text",
        placeholder: "Zip Code",
        value: "",
        label: "Zip Code",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Zip Code",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "zipCode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
        customActions: customActions
      },
      {
        name: "nearbyLandmarks",
        fieldName: "nearbyLandmarks",
        type: "text",
        placeholder: "LandMark",
        value: "",
        label: "LandMark",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "LandMark",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "nearbyLandmarks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "nearbyLandmarks",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "totalTowers",
        fieldName: "totalTowers",
        type: "number",
        placeholder: "Total Towers",
        value: "",
        label: "Total Towers",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Total Towers",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "totalTowers",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "totalTowers",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "totalUnits",
        fieldName: "totalUnits",
        type: "number",
        placeholder: "Units",
        value: "",
        label: "Units",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Units",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "totalUnits",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "totalUnits",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "totalAreaOfProject",
        fieldName: "totalAreaOfProject",
        type: "text",
        placeholder: "Area Of Project",
        value: "",
        label: "Area Of Project",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        stringType: null,
        header: "Area Of Project",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "totalAreaOfProject",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "totalAreaOfProject",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "projectBrochure",
        fieldName: "projectBrochure",
        type: "fileUpload",
        placeholder: "Project Brochure",
        value: "",
        label: "Project Brochure",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Project Brochure",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "projectBrochure",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "other",
        maxFileSize: 5,
        isMultipleRequired: true,
        imagePath: "My Properties",
        textAlign: "center",
        show: true,
        field: "projectBrochure",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "browsePhotos",
        fieldName: "browsePhotos",
        type: "fileUpload",
        placeholder: "Images",
        value: "",
        label: "Project Images",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Project Images",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "browsePhotos",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 10,
        isMultipleRequired: true,
        imagePath: "My Properties",
        textAlign: "center",
        show: true,
        field: "browsePhotos",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "projectVideoLink",
        fieldName: "projectVideoLink",
        type: "text",
        placeholder: "Project Video Link",
        value: "",
        label: "Project Video Link",
        width: "120px",
        addFormOrder: 19,
        editFormOrder: 19,
        stringType: null,
        header: "Project Video Link",
        derivedValue: "projectVideoLink=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "projectVideoLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "projectVideoLink",
        fieldType: 'WebSite',
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "floorPlans",
        fieldName: "floorPlans",
        type: "fileUpload",
        placeholder: "Floor Plans",
        value: "",
        label: "Floor Plans",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Floor Plans",
        derivedValue: "floorPlans=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "floorPlans",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "other",
        maxFileSize: 6,
        isMultipleRequired: true,
        imagePath: "My Properties",
        textAlign: "center",
        show: true,
        field: "floorPlans",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "paymentPlans",
        fieldName: "paymentPlans",
        type: "fileUpload",
        placeholder: "Payment Plans",
        value: "",
        label: "Payment Plans",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Payment Plans",
        derivedValue: "paymentPlans=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "paymentPlans",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "other",
        maxFileSize: 6,
        isMultipleRequired: true,
        imagePath: "My Properties",
        textAlign: "center",
        show: true,
        field: "paymentPlans",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "leagalInformation",
        fieldName: "leagalInformation",
        type: "fileUpload",
        placeholder: "Legal Information/Title Documents",
        value: "",
        label: "Legal Information/Title Documents",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Legal Information/Title Documents",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "leagalInformation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "other",
        maxFileSize: 6,
        isMultipleRequired: true,
        imagePath: "My Properties",
        textAlign: "center",
        show: true,
        field: "leagalInformation",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "projectFeatures",
        fieldName: "projectFeatures",
        type: "textarea",
        placeholder: "Project Features",
        value: "",
        label: "Project Features",
        width: "120px",
        addFormOrder: 20,
        editFormOrder: 20,
        stringType: null,
        header: "Project Features",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "projectFeatures",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "projectFeatures",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "parkingInformation",
        fieldName: "parkingInformation",
        type: "textarea",
        placeholder: "Parking Information",
        value: "",
        label: "Parking Information",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Parking Information",
        derivedValue: "parkingInformation=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "parkingInformation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "parkingInformation",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "constructionPhase",
        fieldName: "constructionPhase",
        type: "textarea",
        placeholder: "Construction Phase",
        value: "",
        label: "Construction Phase",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Construction Phase",
        derivedValue: "constructionPhase=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "constructionPhase",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "constructionPhase",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "approvalAuthority",
        fieldName: "approvalAuthority",
        type: "text",
        placeholder: "Approval Authority / Compliance",
        value: "",
        label: "Approval Authority / Compliance",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Approval Authority / Compliance",
        derivedValue: "approvalAuthority=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "approvalAuthority",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "approvalAuthority",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "agentViewCount",
        fieldName: "agentViewCount",
        type: "text",
        placeholder: "Views",
        value: "",
        label: "Views",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        header: "Views",
        derivedValue: "agentViewCount=agentViewCount=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "agentViewCount",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: true,
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "right",
        show: true,
        field: "onViewCount",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'icon',
        iconName: 'eye',
        showDefalutValue: '0'
      },
    ];
  };

  const getBuilderFormFields = () => {

    return [
      {
        name: "projectName",
        fieldName: "projectName",
        type: "text",
        placeholder: "Project Name",
        value: "",
        label: "Project Name",
        width: "120px",
        addFormOrder: 1,
        editFormOrder: 1,
        stringType: null,
        derivedValue: "projectName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "projectName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "client",
        fieldName: "client",
        type: "relateAutoComplete",
        placeholder: "Builder Name",
        value: "",
        label: "Builder Name",
        width: "150px",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "client",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        controllerName: "Clients",
        searchApi: "clients",
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customWidth: 'col-3',
        criteria: [{ "key": "type", "value": ["Seller"], "type": "in" }, { key: "status", value: ["Active"], type: "in" }]
      },
      {
        name: "addCLient",
        fieldName: "addClient",
        type: "customFormField",
        placeholder: "Add Builder",
        label: "Add Builder",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        id: "addCLient",
        displayinaddForm: "true",
        displayineditForm: "true",
        isAddFormHidden: true,
        isEditFormHidden: true,
        customFormField: onCLickAddClient,
        show: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customWidth: 'col-1'
      },
      {
        name: "propertyType",
        fieldName: "propertyType",
        type: "radio",
        placeholder: "Type",
        value: "apartment",
        label: "Property Type",
        width: "150px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: null,
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Apartment", value: "apartment", id: "msr" },
          { label: "Villas", value: "villas", id: "msr" },
          { label: "Commercial", value: "commercial", id: "msr" },
        ],
        fieldType: "radio",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        dependent: [{ "apartment": ["minBedRooms", "maxBedRooms", "minBathRooms", "maxBathRooms", "totalTowers", "numberOfFloors", "totalUnits"] }, { "villas": ["minBedRooms", "maxBedRooms", "minBathRooms", "maxBathRooms", "totalTowers", "numberOfFloors", "totalUnits"]},],
      },
      {
        name: "availability",
        fieldName: "availability",
        type: "radio",
        placeholder: "Availability",
        value: "Under Construction",
        label: "Availability",
        width: "150px",
        addFormOrder: 4,
        editFormOrder: 4,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "availability",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
          { label: "Up Coming", value: "upComing", id: "msr" },
        ],
        fieldType: "radio",
        show: true,
        showOrHideFields: [],
        label0: "Under Construction",
        value0: "Under Construction",
        label1: "Ready To Move",
        value1: "Ready To Move",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        dependent: [{ "upComing": ['priceForSqft'] }, { "Under Construction": ['priceForSqft'] }, { "Ready To Move": ["minPrice", "maxPrice"] }],
      },
      {
        name: "minPrice",
        fieldName: "minPrice",
        type: "dropDown",
        placeholder: "Min",
        value: "",
        label: "Price",
        width: "180px",
        addFormOrder: 4,
        editFormOrder: 4,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "minPrice",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: SoldPriceOptions,
        fieldType: "dropDown",
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customWidth: 'col-2',
        displayPrice: true
      },
      {
        name: "maxPrice",
        fieldName: "maxPrice",
        type: "dropDown",
        placeholder: "Max",
        value: "",
        // label: "Price",
        width: "180px",
        addFormOrder: 4,
        editFormOrder: 4,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "maxPrice",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: SoldPriceOptions,
        fieldType: "dropDown",
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customWidth: 'col-2 custom-pt-20',
        displayPrice: true,
      },
      {
        name: "priceForSqft",
        fieldName: "priceForSqft",
        type: "number",
        placeholder: "Price",
        value: "",
        label: "Price per unit",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        stringType: null,
        derivedValue: "priceForSqft=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "priceForSqft",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        displayPrice: true,
        customWidth: 'col-2 ',
        valuePattern: /^\d{1,5}$/,
      },
      {
        name: "areaUnits",
        fieldName: "areaUnits",
        type: "dropDown",
        placeholder: "Area Units",
        value: "sqft",
        label: "Area Units",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        stringType: null,
        derivedValue: "areaUnits=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "areaUnits",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: PropertyAeraOptions,
        fieldType: "dropDown",
        filterElement: PropertyAeraOptions,
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        customWidth: 'col-2 ',
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "possessionDate",
        fieldName: "possessionDate",
        type: "date",
        placeholder: "Possession Date",
        value: "",
        label: "Possession Date",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        stringType: null,
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        id: "possessionDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        minDate: new Date(),
      },
      {
        name: "city",
        fieldName: "city",
        type: "relateAutoComplete",
        placeholder: "City",
        value: "",
        label: "City",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        derivedValue: "city=city=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        searchField: "city",
        fieldType: "relateAutoComplete",
        controllerName: "localities",
        searchApi: "localities",
      },
      {
        name: "locality",
        fieldName: "locality",
        type: "relateAutoComplete",
        placeholder: "Locality",
        value: "",
        label: "Locality",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        derivedValue: "locality=locality=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "locality",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        searchField: "area",
        fieldType: "relateAutoComplete",
        controllerName: "localities",
        searchApi: "localities",
        hasDependencyField: "city",
      },
      {
        name: "address",
        fieldName: "address",
        type: "text",
        placeholder: "Street Address",
        value: "",
        label: "Street Address",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        maxLength: 250
      },
      {
        name: "zipCode",
        fieldName: "zipCode",
        type: "number",
        placeholder: "Zip Code",
        value: "",
        label: "Zip Code",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        maxLength: 6,
        valuePattern: /^\d{1,6}$/
      },
      {
        name: "nearbyLandmarks",
        fieldName: "nearbyLandmarks",
        type: "text",
        placeholder: "NearBy Landmarks",
        value: "",
        label: "NearBy Landmark",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        stringType: null,
        header: "Landmark",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "nearbyLandmarks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "nearbyLandmarks",
        showOrHideFields: [],
        mobile: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        displayInSettings: true,
        tDisplay: true,
        maxLength: 250
      },
      {
        name: "totalTowers",
        fieldName: "totalTowers",
        type: "number",
        placeholder: "Total Towers",
        value: "",
        label: "Total Towers",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "totalTowers",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        valuePattern: /^\d{1,2}$/,
      },
      {
        name: "numberOfFloors",
        fieldName: "numberOfFloors",
        type: "number",
        placeholder: "Total Floors",
        value: "",
        label: "Total Floors",
        width: "180px",
        addFormOrder: 13,
        editFormOrder: 13,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "totalFloors",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        valuePattern: /^\d{1,2}$/,
      },
      {
        name: "totalUnits",
        fieldName: "totalUnits",
        type: "number",
        placeholder: "Units",
        value: "",
        label: "Units",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "totalUnits",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        valuePattern: /^\d{1,4}$/,
      },
      {
        name: "totalAreaOfProject",
        fieldName: "totalAreaOfProject",
        type: "text",
        placeholder: "Area Of Project",
        value: "",
        label: "Area Of Project",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "totalAreaOfProject",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "minArea",
        fieldName: "minArea",
        type: "number",
        placeholder: "Min",
        value: "",
        label: "Area",
        width: "160px",
        addFormOrder: 5,
        editFormOrder:5,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "minArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customWidth: 'col-2',
        valuePattern: /^\d{1,5}$/
      },
      {
        name: "maxArea",
        fieldName: "maxArea",
        type: "number",
        placeholder: "Max",
        value: "",
        width: "160px",
        addFormOrder: 5,
        editFormOrder: 5,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "maxArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customWidth: 'col-2 custom-pt-20',
        valuePattern: /^\d{1,5}$/
      },
      {
        name: "minBedRooms",
        fieldName: "minBedRooms",
        type: "dropDown",
        placeholder: "Min",
        value: "",
        label: "BedRooms",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "minBedRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "1.5", value: "1.5", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "2.5", value: "2.5", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "3.5", value: "3.5", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "4.5", value: "4.5", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "5.5", value: "5.5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
          { label: "6.5", value: "6.5", id: "msr" },
        ],
        fieldType: "radio",
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customWidth: 'col-2'
      },
      {
        name: "maxBedRooms",
        fieldName: "maxBedRooms",
        type: "dropDown",
        placeholder: "Max",
        value: "",
        // label: "Bed Rooms",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "maxBedRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "1.5", value: "1.5", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "2.5", value: "2.5", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "3.5", value: "3.5", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "4.5", value: "4.5", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "5.5", value: "5.5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
          { label: "6.5", value: "6.5", id: "msr" },
        ],
        fieldType: "radio",
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customWidth: 'col-2 custom-pt-20'
      },
      {
        name: "minBathRooms",
        fieldName: "minBathRooms",
        type: "dropDown",
        placeholder: "Min",
        value: "",
        label: "BathRooms",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "minBathRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customWidth: 'col-2'
      },
      {
        name: "maxBathRooms",
        fieldName: "maxBathRooms",
        type: "dropDown",
        placeholder: "Max",
        value: "",
        // label: "Bath Rooms",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "maxBathRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customWidth: 'col-2 custom-pt-20'
      },
      {
        name: "facing",
        fieldName: "facing",
        type: "checkbox",
        placeholder: "Facing",
        value: "",
        label: "Facing",
        width: "120px",
        addFormOrder: 20,
        editFormOrder: 20,
        stringType: null,
        derivedValue: "facing=facing=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "facing",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "checkbox",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        multiple: true,
        multipleSelect: true,
        optionsWidth: 'col-sm-3'
      },
      {
        name: "amenties",
        fieldName: "amenties",
        type: "checkbox",
        placeholder: "Amenties",
        value: "",
        label: "Amenities",
        width: "120px",
        addFormOrder: 21,
        editFormOrder: 21,
        stringType: null,
        derivedValue: "amenties=amenties=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "amenties",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        options: amenitiesOptions,
        fieldType: "checkbox",
        multiple: true,
        multipleSelect: true,
        optionsWidth: 'col-sm-4'
      },
      {
        name: "description",
        fieldName: "description",
        type: "textarea",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "200px",
        addFormOrder: 23,
        editFormOrder: 23,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "propertyStatus",
        fieldName: "propertyStatus",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 25,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "propertyStatus",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "Expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "projectVideoLink",
        fieldName: "projectVideoLink",
        type: "text",
        placeholder: "Project Video Link",
        value: "",
        label: "Project Video Link",
        width: "120px",
        addFormOrder: 21,
        editFormOrder: 21,
        stringType: null,
        derivedValue: "projectVideoLink=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "projectVideoLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "approvalAuthority",
        fieldName: "approvalAuthority",
        type: "text",
        placeholder: "Approval Authority / Compliance",
        value: "",
        label: "Approval Authority / Compliance",
        width: "120px",
        addFormOrder: 19,
        editFormOrder: 19,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "approvalAuthority",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "projectFeatures",
        fieldName: "projectFeatures",
        type: "textarea",
        placeholder: "Project Features",
        value: "",
        label: "Project Features",
        width: "120px",
        addFormOrder: 22,
        editFormOrder: 22,
        stringType: null,
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "projectFeatures",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "parkingInformation",
        fieldName: "parkingInformation",
        type: "textarea",
        placeholder: "Parking Information",
        value: "",
        label: "Parking Information",
        width: "120px",
        addFormOrder: 21,
        editFormOrder: 21,
        stringType: null,
        derivedValue: "parkingInformation=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "parkingInformation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "constructionPhase",
        fieldName: "constructionPhase",
        type: "textarea",
        placeholder: "Construction Phase",
        value: "",
        label: "Construction Phase",
        width: "150px",
        addFormOrder: 22,
        editFormOrder: 22,
        stringType: null,
        derivedValue: "constructionPhase=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "constructionPhase",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "projectBrochure",
        fieldName: "projectBrochure",
        type: "fileUpload",
        placeholder: "Project Brochure",
        value: "",
        label: "Project Brochure",
        width: "120px",
        addFormOrder: 26,
        editFormOrder: 26,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "projectBrochure",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "other",
        maxFileSize: 10,
        acceptType: "image/*, .pdf",
        isMultipleRequired: true,
        imagePath: "My Properties",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "browsePhotos",
        fieldName: "browsePhotos",
        type: "fileUpload",
        placeholder: "Property Images",
        value: "",
        label: "Property Images",
        width: "120px",
        addFormOrder: 25,
        editFormOrder: 25,
        stringType: null,
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "browsePhotos",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 10,
        acceptType: "image/*, video/*, .pdf",
        isMultipleRequired: true,
        imagePath: "My Properties",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "floorPlans",
        fieldName: "floorPlans",
        type: "fileUpload",
        placeholder: "Floor Plans",
        value: "",
        label: "Floor Plans",
        width: "120px",
        addFormOrder: 27,
        editFormOrder: 27,
        stringType: null,
        derivedValue: "floorPlans=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "floorPlans",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "other",
        maxFileSize: 10,
        acceptType: "image/*, .pdf",
        isMultipleRequired: true,
        imagePath: "My Properties",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "paymentPlans",
        fieldName: "paymentPlans",
        type: "fileUpload",
        placeholder: "Payment Plans",
        value: "",
        label: "Payment Plans",
        width: "120px",
        addFormOrder: 28,
        editFormOrder: 28,
        stringType: null,
        derivedValue: "paymentPlans=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "paymentPlans",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "other",
        maxFileSize: 10,
        acceptType: "image/*, .pdf",
        isMultipleRequired: true,
        imagePath: "My Properties",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "leagalInformation",
        fieldName: "leagalInformation",
        type: "fileUpload",
        placeholder: "Legal Information/Title Documents",
        value: "",
        label: "Legal Info / Title Docs",
        width: "120px",
        addFormOrder: 29,
        editFormOrder: 29,
        stringType: null,
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "leagalInformation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "other",
        maxFileSize: 10,
        acceptType: "image/*, .pdf, .doc, .docx,",
        isMultipleRequired: true,
        imagePath: "My Properties",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "title",
        fieldName: "title",
        type: "derivedFields",
        placeholder: "Title",
        value: "",
        label: "Title",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: "String",
        header: "Title",
        derivedValue: "title=city+locality",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: ["city", "locality"],
        id: "title",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "title",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "validateRequirement",
        fieldName: "validateRequirement",
        type: "textarea",
        placeholder: "ValidateRequirement",
        value: "",
        label: "Validate Requirement",
        width: "120px",
        addFormOrder: 1,
        editFormOrder: 1,
        stringType: null,
        header: "Validate Requirement",
        derivedValue: "validateRequirement=validateRequirement=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "validateRequirement",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "validateRequirement",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: 110,
        addFormOrder: 2,
        editFormOrder: 10,
        header: "Name",
        derivedValue: "name=name=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "name",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "propertyFor",
        fieldName: "propertyFor",
        type: "radio",
        placeholder: "Property For",
        value: "",
        label: "Property For",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        header: "Property For",
        derivedValue: "propertyFor=propertyFor=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyFor",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        textAlign: "center",
        show: false,
        field: "propertyFor",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyType",
        fieldName: "propertyType",
        type: "radio",
        placeholder: "Property Type",
        value: "",
        label: "Property Type",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: null,
        header: "Property Type",
        derivedValue: "propertyType=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1RK", value: "1Rk", id: "msr" },
          { label: "Flat", value: "Flat", id: "msr" },
          { label: "Duplex", value: "Duplex", id: "msr" },
          { label: "Bungalow", value: "Banglow", id: "msr" },
          { label: "Commercial", value: "Commercial", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1RK", value: "1Rk", id: "msr" },
          { label: "Flat", value: "Flat", id: "msr" },
          { label: "Duplex", value: "Duplex", id: "msr" },
          { label: "Bungalow", value: "Banglow", id: "msr" },
          { label: "Commercial", value: "Commercial", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "propertyType",
        showOrHideFields: [],
        label0: "1RK",
        value0: "1Rk",
        label1: "Flat",
        value1: "Flat",
        label2: "Duplex",
        value2: "Duplex",
        label3: "Bungalow",
        value3: "Banglow",
        label4: "Commercial",
        value4: "Commercial",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "price",
        fieldName: "price",
        type: "number",
        placeholder: "Price",
        value: "",
        label: "Price",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        header: "Price",
        derivedValue: "price=price=price=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "price",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "price",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "rent",
        fieldName: "rent",
        type: "number",
        placeholder: "Rent",
        value: "",
        label: "Rent",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        stringType: null,
        header: "Rent",
        derivedValue: "rent=rent=rent=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "rent",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "rent",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "deposite",
        fieldName: "deposite",
        type: "number",
        placeholder: "Deposite",
        value: "",
        label: "Deposite",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        stringType: null,
        header: "Deposite",
        derivedValue: "deposite=deposite=deposite=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "deposite",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "deposite",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "facing",
        fieldName: "facing",
        type: "dropDown",
        placeholder: "Facing",
        value: "",
        label: "Facing",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        stringType: null,
        header: "Facing",
        derivedValue: "facing=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "facing",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "facing",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "city",
        fieldName: "city",
        type: "text",
        placeholder: "City",
        value: "",
        label: "City",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 5,
        stringType: null,
        header: "City",
        derivedValue: "city=city=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "locality",
        fieldName: "locality",
        type: "text",
        placeholder: "Locality",
        value: "",
        label: "Locality",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 4,
        stringType: null,
        header: "Locality",
        derivedValue: "locality=locality=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "locality",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: false,
        field: "locality",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "landmark",
        fieldName: "landmark",
        type: "text",
        placeholder: "Landmark",
        value: "",
        label: "Landmark",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        header: "Landmark",
        derivedValue: "landmark=landmark=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "landmark",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "landmark",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "plotArea",
        fieldName: "plotArea",
        type: "number",
        placeholder: "Plot Area",
        value: "",
        label: "Plot Area",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        stringType: null,
        header: "Plot Area",
        derivedValue: "plotArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "plotArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "plotArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "carpetArea",
        fieldName: "carpetArea",
        type: "number",
        placeholder: "Carpet Area",
        value: "",
        label: "Carpet Area",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 12,
        stringType: null,
        header: "Carpet Area",
        derivedValue: "carpetArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "carpetArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "carpetArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "builtUpArea",
        fieldName: "builtUpArea",
        type: "number",
        placeholder: "Built Up Area",
        value: "",
        label: "Built Up Area",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 13,
        stringType: null,
        header: "Built Up Area",
        derivedValue: "builtUpArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "builtUpArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "builtUpArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "floorNumber",
        fieldName: "floorNumber",
        type: "number",
        placeholder: "Floor Number",
        value: "",
        label: "Floor Number",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        stringType: null,
        header: "Floor Number",
        derivedValue: "floorNumber=floorNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "floorNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "floorNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfFloors",
        fieldName: "numberOfFloors",
        type: "number",
        placeholder: "Number Of Floors",
        value: "",
        label: "Number Of Floors",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        stringType: null,
        header: "Number Of Floors",
        derivedValue: "numberOfFloors=numberOfFloors=numberOfFloors=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "numberOfFloors",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "numberOfFloors",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "availability",
        fieldName: "availability",
        type: "radio",
        placeholder: "Availability",
        value: "",
        label: "Availability",
        width: "120px",
        addFormOrder: 19,
        editFormOrder: 19,
        stringType: null,
        header: "Availability",
        derivedValue: "availability=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "availability",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "availability",
        showOrHideFields: [],
        label0: "Under Construction",
        value0: "Under Construction",
        label1: "Ready To Move",
        value1: "Ready To Move",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "furnishingType",
        fieldName: "furnishingType",
        type: "radio",
        placeholder: "Furnishing Type",
        value: "",
        label: "Furnishing Type",
        width: "120px",
        addFormOrder: 20,
        editFormOrder: 20,
        stringType: null,
        header: "Furnishing Type",
        derivedValue: "furnishingType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "furnishingType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr" },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" },
          { label: "Furnished", value: "Furnished", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr" },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" },
          { label: "Furnished", value: "Furnished", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "furnishingType",
        showOrHideFields: [],
        label0: "Un Furnished",
        value0: "Un Furnished",
        label1: "Semi Furnished",
        value1: "Semi Furnished",
        label2: "Furnished",
        value2: "Furnished",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "amenties",
        fieldName: "amenties",
        type: "textarea",
        placeholder: "Amenties",
        value: "",
        label: "Amenties",
        width: "120px",
        addFormOrder: 21,
        editFormOrder: 21,
        stringType: null,
        header: "Amenties",
        derivedValue: "amenties=amenties=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "amenties",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "amenties",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyDescription",
        fieldName: "propertyDescription",
        type: "textarea",
        placeholder: "Property Description",
        value: "",
        label: "Property Description",
        width: "120px",
        addFormOrder: 23,
        editFormOrder: 23,
        stringType: null,
        header: "Property Description",
        derivedValue: "propertyDescription=propertyDescription=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "propertyDescription",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "propertyDescription",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "browsePhotos",
        fieldName: "browsePhotos",
        type: "fileUpload",
        placeholder: "Browse Photos",
        value: "",
        label: "Browse Photos",
        width: "120px",
        addFormOrder: 24,
        editFormOrder: 24,
        stringType: null,
        header: "Browse Photos",
        derivedValue: "browsePhotos=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "browsePhotos",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 1,
        isMultipleRequired: false,
        imagePath: "My Properties",
        textAlign: "center",
        show: true,
        field: "browsePhotos",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "client",
        fieldName: "client",
        type: "relateAutoComplete",
        placeholder: "Client",
        value: "",
        label: "Client",
        width: "120px",
        addFormOrder: 22,
        editFormOrder: 22,
        stringType: null,
        header: "Client",
        derivedValue: "client=client=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "client",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1008,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["firstName"],
        controllerName: "Clients",
        searchApi: "clients",
        textAlign: "center",
        show: false,
        field: "client",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Clients_firstName",
        type: "text",
        placeholder: "Clients_firstName",
        label: "Clients_firstName",
        header: "Clients firstName",
        width: 110,
        parentField: "client",
        id: "Clients_firstName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "firstName",
        show: true,
        field: "Clients_firstName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "monthlyMaintenance",
        fieldName: "monthlyMaintenance",
        type: "number",
        placeholder: "MonthlyMaintenance",
        value: "",
        label: "MonthlyMaintenance",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "MonthlyMaintenance",
        derivedValue: "monthlyMaintenance=monthlyMaintenance=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "monthlyMaintenance",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: false,
        field: "monthlyMaintenance",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "bedRooms",
        fieldName: "bedRooms",
        type: "radio",
        placeholder: "Bed Rooms",
        value: "",
        label: "Bed Rooms",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        stringType: null,
        header: "Bed Rooms",
        derivedValue: "bedRooms=bedRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "bedRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        textAlign: "center",
        show: false,
        field: "bedRooms",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "bathRooms",
        fieldName: "bathRooms",
        type: "radio",
        placeholder: "Bath Rooms",
        value: "",
        label: "Bath Rooms",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        stringType: null,
        header: "Bath Rooms",
        derivedValue: "bathRooms=bathRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "bathRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        textAlign: "center",
        show: false,
        field: "bathRooms",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "text",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Status",
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "status",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyStatus",
        fieldName: "propertyStatus",
        type: "dropDown",
        placeholder: "Property Status",
        value: "",
        label: "Property Status",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 25,
        stringType: null,
        header: "Property Status",
        derivedValue: "propertyStatus=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "propertyStatus",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "propertyStatus",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created By",
        derivedValue: "createdByName=createdByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 25,
        editFormOrder: 26,
        header: "Updated By",
        derivedValue: "updatedByName=updatedByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: false,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        textAlign: "center",
        show: false,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    setopenFormModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field) {
      let selectedIds = item.map((row) => row._id);
        userBody = {
          propertyIds: selectedIds,
          type: value ? 'Favourite' : 'UnFavourite'
        };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `favourites/createFavourites`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success"); 
            if (dataTableRef.current) {
              const { getDataFromServer, filterCriteria } = await dataTableRef.current;
              getDataFromServer(filterCriteria);
            }
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const setValueOnSuccessParent = (response) => {
    if(setValueOnSuccessRef.current){
      let settingObject = { field : 'client' , fieldType : 'relateAutoComplete' , apiUrl : apiCalls.clients ,responseKey : 'clientsId' , method : 'GET', filterKey : '_id'};
      setValueOnSuccessRef.current(settingObject,response);
    }     
  }

  const getFormModal = () => {
    return <FormModal
        openFormModal={openFormModal}
        tableRowData={selectedRowData}
        closeFormModal={cancelReset}
        type={"Clients"}
        tablefieldsToShow={getTableFields}
        originalTableFields={getTableFields}
        formType={'add'}
        formFields={() =>getClientFormFields()}
        filterCriteria={{}}
        getDataFromServer={dataTableRef.current?.getDataFromServer}
        editRequired={true}
        apiUrl={actionApiUrls}
        routeTo={'myProperties'}
        displayViewOfForm={'modal'}
        entityType={'myProperties'}
        selectedId={selectedRowData._id}
        setValueOnSuccess={setValueOnSuccessParent}
        isSearch={true}
      />
  }

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "Favourite",
      action: saveDataToServer,
      options: [
        {
          label: "Favourite",
          field: "isFavourite",
          value: true,
          show: isEditPermission,
          multiple: true,
        },
      ],
    },
    {
      name: "UnFavourite",
      action: saveDataToServer,
      options: [
        {
          label: "UnFavourite",
          field: "isFavourite",
          value: false,
          show: isEditPermission,
          multiple: true,
        },
      ],
    },
  ];


  const onCLickAddClient = (index, item, setValueOnSuccess) => {
    setValueOnSuccessRef.current = setValueOnSuccess
    return <div className="d-flex justify-content-end mt-4">
      <Button color='p-button p-component' type="button" onClick={addClient}>
        {selectedPropertyType === 'builder' ? 'Add Builder' : 'Add Client'}
      </Button>
    </div>
  }

  const reNewProperty = (item) => {
    let url = `myProperties/renew?type=property&&propertyId=${item._id}`;
    let method = 'POST';
    fetchMethodRequest(method, url).then(async res => {
      if (res && res.respCode) {
        closeConfirmationModal();
        showToasterMessage(res.respMessage, 'success')
        if (dataTableRef.current) {
          const { getDataFromServer, filterCriteria } = dataTableRef.current;
          getDataFromServer(filterCriteria);
        }
      } else {
        showToasterMessage(res.errorMessage, 'error')
      }
    }).catch(err => {
      showToasterMessage(err, 'error')
      return err
    })
  }

  const customActions = (item, tableItem, actionItems, modal, toggleFavorite) => {
    let isFavourite = item.isFavouriteProperty ? 'Unfavorite' : 'Favorite'
   return  <>
   <ShareProperty item={item} />
   <FontAwesomeIcon
      icon={faClone}
      className='shareIconAlignment cursorPointer mx-2'
      title="Clone Property"
      onClick={() => actionItems?.openFormModal(item,'edit', false, 'clone')}
    />
     <FontAwesomeIcon
       icon={item.isFavouriteProperty ? solidHeart : regularHeart}
       className={`cursorPointer ${item.isFavouriteProperty ? 'text-danger' : 'text-dark'}`}
       title={item.isFavouriteProperty ? 'UnFavorite' : 'Favorite'}
       onClick={() => toggleFavorite(item, isFavourite)}
     />
   </>
  }

  const actionFieldWithValue = (item) => {
    return <>
      {(item?.expiringSoon && item.expiringSoon !== 'false') &&
      <span>
        <small className="text-danger">{item?.expiringSoon}</small>&nbsp;&nbsp;
      <FontAwesomeIcon
      icon={faSync}
      className='shareIconAlignment cursorPointer'
      title="ReNew Property"
      onClick={() => {
        setOpenConfirmationModal(true); setRowData(item);
      }}
    />
      </span>
    }
    </>
  }

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  }

  const getConfirmatioinModal = () => {
    return (
      <ConfirmationModal
        openConfirmationModal={openConfirmationModal}
        closeConfirmationModal={closeConfirmationModal}
        confirm={() => reNewProperty(rowData)}
        text={'Renew property'}
      />
    )
  }

  const onChangeCustomHeader = () => {
    setSelectedPropertyType('builder')
  }
  
  const customHeader = () => {
    return (
      <div className='col-7 d-flex'>
        <label className="radio-label mr-4">
          <input
            type="radio"
            name="propertyType"
            value="builder"
            checked={selectedPropertyType === 'builder'}
            onChange={() => onChangeCustomHeader()}
          />
          <span className="pl-1 fs-5">Builder Properties</span>
        </label>

        <label className="radio-label ml-4">
          <input
            type="radio"
            name="propertyType"
            value="non-builder"
            checked={selectedPropertyType === 'non-builder'}
            onChange={() => setSelectedPropertyType('non-builder')}
          />
          <span className="pl-1 fs-5">Non Builder Properties</span>
        </label>
      </div>
    )
  }

  return (
    <span>
      {openFormModal && getFormModal()}
      {openConfirmationModal && rowData && getConfirmatioinModal()}
      {showDataTable &&
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={selectedPropertyType === 'builder' ? getBuilderTableFields : getTableFields}
          formFields={selectedPropertyType === 'builder' ? getBuilderFormFields : getFormFields}
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={false}
          sample={isEditPermission}
          printRequried={false}
          actionsTypes={actionTypes}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.myProperties}
          globalSearch={
            "title/validateRequirement/name/propertyFor/propertyType/price/rent/deposite/facing/city/locality/landmark/plotArea/carpetArea/builtUpArea/floorNumber/numberOfFloors/availability/furnishingType/amenties/propertyDescription/browsePhotos/client/monthlyMaintenance/bedRooms/bathRooms/status/propertyStatus"
          }
          displayName="My Properties"
          type="My Properties"
          routeTo={apiCalls.myProperties}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.myProperties?.toLowerCase()}
          apiUrl={apiCalls.myProperties}
          selectedId={params.id}
          displayActionsInTable={"actions"}
          globalShareRequired={true}
          filterClearRequired={true}
          screenHeader={'Property'}
          customType={"myproperties"}
          defaultCriteria={[
            { key: "propertyStatus", value: ["New"], type: "in" },
          ]}
          customHeaders={customHeader}
          selectedPropertyType={selectedPropertyType}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="myProperties"
          apiUrl={apiCalls.myProperties}
        />
      ) : null}
    </span>
  );
};

export default MyProperties;
