import React, { useContext, useEffect } from 'react';
import { Button, ModalBody } from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DoAutoCompleteField from '../../Form/Fields/DoAutoCompleteField';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import { OverallContext } from '../Searches/components/OverallContext';

const ClientSelectModal = (props) => {

    const {isSearchButtonEnabled, setIsSearchButtonEnabled,enableSaveSearchButton}=useContext(OverallContext)

    useEffect(() => {
        const modal = document.querySelector('div[tabindex="-1"][style*="z-index: 1050;"]');
        if (modal) {
            modal.style.zIndex = '300';
        }
        if (props.formType === 'edit') {
            let clientData = {
                clientSelected: {
                    _id: props.locationState.clientId,
                    firstName: props.locationState.clientName
                }
            }
            submit(clientData);
        }
    }, [])

    const schema = yup.object().shape({
        clientSelected: yup.object().required('Select from the dropdown button')
    });

    let {
        handleSubmit,
        formState: { errors },
        control,
        setFocus,
    } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(()=>{
        if(errors.clientSelected)setFocus("clientSelected");

    },[errors,setFocus])

    const submit = (values) => {
        let apiUrl = 'savedSearches';
        let Method = 'POST';
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        let clientCriteria = JSON.parse(localStorage.getItem('searchFiltersData'));
        localStorage.removeItem('searchFiltersData');
        let clientId = values?.clientSelected?._id;
        let selectedProperties = props.properties ? props.properties : [];
        let selectedIds = [];

        if (selectedProperties && selectedProperties.length > 0) {
            for (let i = 0; i < selectedProperties.length; i++) {
                selectedIds.push(selectedProperties[i]._id);
            }
        }
        if (props.locationState && Object.keys(props.locationState).length > 0) {
            Method = 'PUT';
            apiUrl = `savedSearches/${props.locationState._id}`;
        }
        if (props.formType === 'edit') {
            props.setIsOpenClientSelectModel(false);
        }

        let body = {
            agentEmail: user.email,
            clientId: clientId,
            clientName: values?.clientSelected?.firstName,
            criteria: clientCriteria,
            result: selectedIds,
            search: { filter: props.saveSearchFilterObject }
        }

        if (clientId && clientCriteria) {
            fetchMethodRequest(Method, apiUrl, body).then(async resp => {
                if (resp && resp.respCode) {
                    showToasterMessage(resp.respMessage, 'success')
                    await props.setIsOpenClientSelectModel(false);
                } else {
                    showToasterMessage(resp.errorMessage, 'error');
                }
            })
        }
    }


    return (
        <div>
            <Dialog
                className='Client_Modal_Dialog'
                visible={props.formType === 'add' && props.isOpenClientSelectModel}
                draggable={false}
                style={{ width: '45vw' }}
                closeOnEscape={false}
                focusOnShow={false}
                blockScroll={false}
            >
                <ModalBody className='p-0'>
                    <form className="ps-5" onSubmit={handleSubmit(submit)}>
                        <h4 className="text-center mb-3">
                            {'Select a Client to save'}
                        </h4>
                        <div className="form__form-group-field row">
                            <div className='col-12 col-md-9 mb-4'>
                                <Controller
                                    name={"clientSelected"}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoAutoCompleteField
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            field={field}
                                            fieldState={fieldState}
                                            errors={errors}
                                            searchApi={'clients'}
                                            searchField={'firstName'}
                                            placeholder={'Select drop down button'}
                                           criteria={[{ "key": "type", "value": ["Buyer"], "type": "in" },{ "key": "type", "value": ["Seller"], "type": "in" }, { key: "status", value: ["Active"], type: "in" }]}
                                        />)}
                                />
                            </div>
                            <div className="col-12 col-md-3 text-right mt-2">
                                <Button
                                    color="primary"
                                    onClick={() => props.openClientFormModal()}
                                    className='buttonTextWeight'
                                >
                                    {'Add New'}
                                </Button>
                            </div>
                        </div>
                        <div className="col-12 text-center mb-5">
                            <Button
                                color="primary"
                                type="button"
                                className='deleteModalBtn marginRight buttonTextWeight'
                                onClick={() => props.setIsOpenClientSelectModel(false)}
                            >
                                {'Cancel'}
                            </Button>
                            <Button
                                color='primary'
                                type="submit"
                                className='deleteModalBtn buttonTextWeight'
                                onClick={() => {setIsSearchButtonEnabled(false); resetFormData();props.resetSearchFilters();}}
                                
                            >
                                {'Save'}
                            </Button>
                        </div>
                    </form>
                </ModalBody>
            </Dialog>
        </div>
    );
}

export default ClientSelectModal;